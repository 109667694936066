@import 'styles/variables';
@import 'styles/extensions';

.use-cases {

  padding-bottom: 64px;
 
  @media screen and (max-width: 768px) {
    padding-bottom: 32px;
  }

  @media screen and (min-width: 2560px) {
    padding-bottom: calc(64px * #{$zoom});
  }

  &__wrapper {
    @extend %wrapper;
    padding-top: 96px;
    padding-bottom: 96px;
    overflow: hidden;
    color: $black;

    @media (min-width: 2560px) {
      padding-bottom: calc(96px * #{$zoom});
    }

    @media screen and (max-width: 1024px) {
      padding-bottom: 64px;
    }

    @media screen and (max-width: 475px) {
      padding-top: 50px;
      padding-bottom: 32px;
    }
  }

  .sub-title {
    margin-bottom: 0;
  }

  h3 {
    @extend %wrapper;
    max-width: 1140px;
    padding-right: 0;
    padding-bottom: 35px;
    padding-left: 0;
    color: $white;
    font-weight: 800;
    font-size: 40px;
    font-family: $secondary-font--extrabold;
    line-height: 48px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(35px * #{$zoom});
      font-size: calc(40px * #{$zoom});
      line-height: calc(48px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
      font-size: 32px;
      line-height: 36px;
    }

    @media screen and (max-width: 450px) {
      font-size: 24px;
      line-height: 24px;
    }
  }

  &__slider-wrapper {
    display: flex;
    gap: 34px;
    padding-top: 50px;

    & > div {
      display: flex;
      flex: 1;
      min-height: 100%;
    }  
    
    @media screen and (max-width: 650px) {
      flex-direction: column;
    }
  }

  &__slide {
    max-width: 600px;
    padding: 44px 40px 50px;
    color: $white;

    background: linear-gradient(96deg, rgba(235, 236, 240, 0.03) 2.74%, rgba(177, 179, 196, 0.02) 100.72%);
    border: 3px solid rgba(255, 255, 255, 0.05);
    backdrop-filter: blur(75px);

    @media (min-width: 2560px) {
      max-width: calc(600px * #{$zoom});
    }

    @media (min-width: 2560px) {
      max-width: calc(1190px * #{$zoom});
      height: calc(44px * #{$zoom}) calc(40px * #{$zoom}) calc(50px * #{$zoom});
    }

    @media (max-width: 575px) {
      padding: 30px 20px 40px;
    }

    article {
      text-align: left;

      @media (max-width: 880px) {
        flex-basis: 100%;
      }
    }

    p {
      @media (min-width: 2560px) {
        font-size: calc(18px * #{$zoom});
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }

  .embla {
    width: 100%;
    overflow: hidden;
  }

  .embla__container {
    display: flex;
    gap: 24px;
  }

  .embla__slide {
    flex: 0 0 100%;
    min-width: 0;
  }

  .carousel__dots {
    display: flex;
    gap: 12px;
    margin: 0 auto;

    @media screen and (min-width: 1241px) {
      display: none;
    }
  }

  .carousel__dot {
    width: 24px;
    height: 12px;
    background-color: #A4A4A4;
    border: 1px solid black;

    &--active {
      background-color: white;
    }
  } 

  .carousel__extended-wrapper {
    width: 100%;
    max-width: calc(1280px + (24px * 2) + (64px * 2));
    margin: 0 auto;
    padding: 0 0;

    @media screen and (max-width: 1440px) {
      max-width: calc(1280px + (64px * 2));
    }

    @media screen and (max-width: 1340px) {
      max-width: calc(1280px + (64px * 2));
      padding: 0 20px;
    }

    
    @media screen and (min-width: 2560px) {
      max-width: calc(calc(1280px + (24px * 2) + (64px * 2)) * #{$zoom});
    }
  }

  &__carousel {
    position: relative;
    display: flex;
    gap: 24px;
    align-items: center;
    width: 100%;
    // background: linear-gradient(96deg, rgba(235, 236, 240, 0.03) 2.74%, rgba(177, 179, 196, 0.02) 100.72%);
    // backdrop-filter: blur(75px);

    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      background: radial-gradient(50% 50% at 50% 50%, #007CB1 0%, rgba(35, 71, 165, 0.00) 100%);
      border-radius: 1440px;
    }

    @media screen and (max-width: 1240px) {
      display: flex;
      flex-direction: column;
    }
  }

  &__carousel-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 64px;
    min-height: 64px;
    background: transparent;
    border: 2px solid transparent;
    cursor: pointer;

    @media screen and (max-width: 1240px) {
      display: none;
    }

    @media screen and (min-width: 2560px) {
      min-width: calc(64px * #{$zoom});
      min-height: calc(64px * #{$zoom});
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    img {
      position: relative;
    }

    &:hover{ 
      border-color: white;
    }

    &--next {
      transform: rotate(180deg);
    
      img {
        right: 3px;
      }
    }

    &--prev {
      img {
        left: -3px;
      }
    }
  }

  &__carousel-item {
    display: flex;
    gap: 54px;
    padding: 54px 24px;
    border: 3px solid #FFFFFF0D;

    @media screen and (max-width: 1240px) {
      flex-direction: column;
    }

    @media screen and (min-width: 2560px) {
      gap: calc(54px * #{$zoom});
      padding: calc(54px * #{$zoom}) calc(24px * #{$zoom});
    }
  }

  &__item-text {
    max-width: 50%;
    color: white;

    @media screen and (max-width: 1240px) {
      max-width: 100%;
    }
  }

  &__item-description {
    @media screen and (min-width: 2560px) {
      font-size: calc(16px * #{$zoom});
    }
  }

  &__image-wrapper {
    max-width: 50%;

    @media screen and (max-width: 1240px) {
      max-width: 100%;
    }

    img {
      width: 100%;
    }
  }
}