@import 'styles/variables';
@import 'styles/extensions';

.main-title {
  position: relative;
  background: $white;
  aspect-ratio: 16/9;

  @media screen and (max-width: 950px) and (min-width: 450px) {
    min-height: 550px;
  }

  @media screen and (max-width: 450px) {
    height: 100vh;
    min-height: 650px;
    aspect-ratio: initial;
    background: url('../../../../../asset/resource/co-pilot.png'), 
      linear-gradient(180deg, rgba(100, 98, 98, 0.00) -1.79%, #646262 102.45%), 
      $white;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: 40% 65%, 100%, 100%;
    background-size: 145%, 100%, 100%;
  }

  &__wrapper {
    @extend %wrapper;
    position: relative;
    z-index: 1;
    padding-top: 160px;
    padding-bottom: 50px;
    aspect-ratio: 16/9;

    @media (min-width: 2560px) {
      max-width: calc(1280px * #{$zoom});
      min-height: calc(900px * #{$zoom});
      padding-top: calc(150px * #{$zoom});
      padding-bottom: calc(50px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
      padding-top: 135px;
    }

    @media screen and (max-width: 450px) {
      padding-top: 125px;
    }
  }

  &__bg {
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 0;
    width: 100vw;
    padding: 0;
    aspect-ratio: 16/9;

    @media screen and (max-width: 950px) and (min-width: 450px) {
      left: 0;
    }

    &_static {
      position: absolute;

      @media screen and (max-width: 450px) {
        top: 45%;
        right: 0;
        bottom: 0;
        left: initial;
        width: 100%;
        transform: translate(0, 0);
      }
    }
  }

  &__animation-block {
    position: relative;
    max-width: 100%;
    height: 100%;

    & > div {
      position: absolute;
      top: 50%;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__animated-image {
    max-width: 90%;
    margin: 0 auto !important;
    border: 1px solid  $white;
    box-shadow: 0px 0px 18px 0px $white;
  }

  h1 {
    position: relative;
    z-index: 100;
    color: $title;
    font-weight: normal;
    font-size: 80px;
    font-family: $secondary-font;
    line-height: normal;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      font-size: calc(88px * #{$zoom});
      line-height: calc(104px * #{$zoom});
    }

    @media screen and (max-width: 1600px) {
      font-size: 74px;
      line-height: normal;
    }

    @media screen and (max-width: 1200px) {
      font-size: 5vw;
      line-height: 5.5vw;
    }

    @media screen and (max-width: 450px) {
      font-size: 11vw;
      line-height: 11.5vw;
    }
  }
  
  &__description {
    position: relative;
    max-width: 65vw;
    text-align: left;

    @media (min-width: 2560px) {
      max-width: 80%;
    }

    @media screen and (max-width: 800px) {
      max-width: 50%;
    }

    @media screen and (max-width: 450px) {
      max-width: 70vw;
    }
  }

  &__subtitle-colored-text {
    display: inline-block;
    margin-bottom: 16px;
    color: $sub-title;
    font-weight: normal;
    font-size: 40px;
    font-family: $secondary-font;
    line-height: 100%;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      max-width: calc(850px * #{$zoom});
      font-size: calc(40px * #{$zoom});
    }

    
    @media screen and (max-width: 1200px) {
      font-size: 3vw;
      line-height: 3vw;
    }

    @media screen and (max-width: 768px) {
      font-size: 2.5vw;
      line-height: 2.5vw;
    }

    @media screen and (max-width: 450px) {
      font-size: 5vw;
      line-height: 100%;
    }
  }
}