@font-face {
  font-weight: 900;
  font-family: 'ProximaNova-Black';
  src: url('https://info.softserveinc.com/hubfs/common-demo-assets/fonts/ProximaNova/ProximaNova-Black.ttf');
}

@font-face{
  font-weight: normal;
  font-family: 'OpenSans';
  src: url('https://info.softserveinc.com/hubfs/fonts/OpenSans-Regular.ttf');
}