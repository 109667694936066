@import 'styles/variables';
@import 'styles/extensions';

.how-it-works {
  background: $how-it-works-bg;

  &__wrapper {
    @extend %wrapper;
    position: relative;
    padding-top: 100px;
    padding-bottom: 150px;
  }

  h2 {
    max-width: 1140px;
    padding-right: 0;
    padding-bottom: 70px;
    padding-left: 0;
    font-weight: 800;
    font-size: 60px;
    font-family: $secondary-font--extrabold;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      max-width: calc(1140px * #{$zoom});
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 40px;
      font-size: 32px;
      line-height: 36px;
    }

    span {
      color: $white;
    }
  }

  &__blocks {
    margin: 0 auto;

    & > div:not(:last-child) {
      margin-bottom: 100px;

      @media (max-width: 768px) {
        margin-bottom: 50px;
      }
    }
  }

  &__block-title {
    padding-bottom: 15px;
    color: $white;
    font-weight: 800;
    font-size: 20px;
    font-family: $secondary-font--extrabold;
    line-height: 120%;

    @media (min-width: 2560px) {
      padding-bottom: calc(10px * #{$zoom});
      font-size: calc(32px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
      font-size: 18px;
    }
  }

  &__block-desc {
    color: $white;

    @media (min-width: 2560px) {
      font-size: calc(18px * #{$zoom});
    }

    p {
      &:not(:last-child) {
        margin-bottom: 40px;

        @media screen and (max-width: 450px) {
          margin-bottom: 20px;
        }
      }
    }
  }

  &__block {
    position: relative;
    display: flex;
    gap: 50px;
    justify-content: space-between;
    margin-bottom: 200px;

    & > div {
      &:first-of-type {
        flex-basis: 55%;
      }
    }

    @media (max-width: 2559px) and (min-width: 1024px) {
      gap: 80px;
    }

    @media (min-width: 2560px) {
      gap: 140px;
      margin-bottom: calc(250px * #{$zoom});
    }

    &:last-child {
      flex-basis: 35%;
      margin-bottom: 0!important;
    }

    &:nth-child(even) {
      flex-direction: row-reverse;

      figure {
          img {
            justify-self: right;

            @media (max-width: 768px) {
              justify-self: center;
            }
        }
      }

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }

    article,
    figure {
      flex-basis: 50%;
    }

    figure {
      display: grid;

      img {
        align-self: center;
        justify-self: left;
        width: 100%;
        max-width: 582px;

        @media (min-width: 2560px) {
          max-width: calc(582px * #{$zoom});
        }

        @media (max-width: 768px) {
          justify-self: center;
        }

        @media (max-width: 475px) {
          max-width: 100%;
        }
      }
    }

    article {
      max-width: 520px;

      @media (min-width: 2560px) {
        max-width: calc(520px * #{$zoom});
        font-size: calc(16px * 1.44);
      }

      @media (max-width: 768px) {        
        max-width: 100%;
      }
    }

    @media (max-width: 768px) {
      flex-direction: column;
      margin-bottom: 200px;
    }

    @media (max-width: 475px) {
      flex-direction: column;
      margin-bottom: 120px;
    }
  }
}