@import 'styles/variables';
@import 'styles/extensions';

.banner {
  position: relative;

  &__wrapper {
    @extend %wrapper;
    display: flex;
    flex-direction: row-reverse;
    gap: 45px;
    justify-content: space-between;
    padding-top: 85px;
    padding-bottom: 85px;

    & > div {
      &:first-of-type {
        flex-basis: 40%;

        @media screen and (max-width: 1024px) {
          flex-basis: 50%;
        }
      }

      &:last-of-type {
        flex-basis: 60%;

        @media screen and (max-width: 1024px) {
          flex-basis: 50%;
        }
      }
    }

    @media (min-width: 2560px) {
      padding-top: calc(85px * #{$zoom});
      padding-bottom: calc(85px * #{$zoom});
    }

    @media (max-width: 860px) {
      flex-direction: column;
    }
  }

  h2 {
    color: $white;
    font-weight: normal;
    font-size: 32px;
    font-family: $secondary-font--extrabold;
    line-height: 100%;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      font-size: calc(32px * #{$zoom});
    }

    @media screen and (max-width: 450px) {
       font-size: 24px;
    }

    span {
      display: block;
      color: $blue;
    }
  }

  &__content {
    color: $white;
    font-size: 20px;

    p {
      &:not(:last-child) {
        margin-bottom: 25px;
      }

      @media (min-width: 2560px) {
        font-size: calc(20px * #{$zoom});
      }
    }
  }
}