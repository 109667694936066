@import 'styles/variables';
@import 'styles/extensions';

.how-to-use {
  padding-top: 100px;
  background-color: $black;

  @media (min-width: 2560px) {
    background-size: calc(350px * #{$zoom});
  }

  &__wrapper {
    @extend %wrapper;
    padding-bottom: 100px;

    @media (min-width: 2560px) {
      padding-bottom: calc(100px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
      padding-bottom: 0;
    }
  }

  h2 {
    padding-right: 0;
    padding-bottom: 70px;
    color: $white;
    font-weight: 800;
    font-size: 60px;
    font-family: $secondary-font--extrabold;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    @media (min-width: 2560px) {
      padding-bottom: calc(90px * #{$zoom});
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media screen and (max-width: 768px) {
      font-size: 48px;
      line-height: 56px;
    }

    @media screen and (max-width: 450px) {
      padding-bottom: 40px;
      font-size: 32px;
      line-height: 36px;
    }

    span {
      color: $white;
    }
  }

  &__items-block {
    margin-top: 70px;

    @media (max-width: 450px) {
      margin-top: 40px;
    }

    h3 {
      padding-bottom: 24px;
      color: $white;
      font-weight: normal;
      font-size: 24px;
      font-family: $secondary-font--extrabold;
      line-height: 125%;
      letter-spacing: 0px;
      text-align: start;

      @media (min-width: 2560px) {
        padding-bottom: calc(35px * #{$zoom});
        font-size: calc(24px * #{$zoom});
      }
    }

    span {
      color: $blue;
      font-weight: normal;
      font-size: 14px;
      line-height: 116%;
      text-transform: uppercase;

      @media (min-width: 2560px) {
        font-size: calc(14px * #{$zoom});
      }
    }
  }

  &__item-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 54px;
    color: $white;

    @media (max-width: 680px) {
      grid-template-columns: 1fr;
      row-gap: 30px;
      margin-top: 0;
      margin-bottom: 40px;
    }

    & > div {
      flex: 1;

      @media (max-width: 680px) {
        flex: 1;
      }
    }

    ul {
      color: $white;
      list-style: none;

      li {
        padding-bottom: 20px;
        color: $white;

        @media (min-width: 2560px) {
          padding-bottom: calc(20px * #{$zoom});
          font-size: calc(16px * #{$zoom});
        }
      }
    }

    img {
      max-width: 350px;
      margin-top: calc(-390px / 2);

      @media (min-width: 2560px) {
        max-width: calc(350px * #{$zoom});
      }

      @media (max-width: 680px) {
        margin-top: 0;
      }
    }
  }

  &__item-instance {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__item-icon {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;

    img {
      width: 100%;
      max-width: 96px;
      margin: 0;
      object-fit: contain;
    }
  }
}