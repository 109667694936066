$zoom: 1.44;

$basic-font: 'OpenSans', Helvetica, Arial, sans-serif;
$secondary-font: 'ProximaNova-Black', sans-serif;
$secondary-font--bold: 'ProximaNova-Black', sans-serif;
$secondary-font--extrabold: 'ProximaNova-Black', sans-serif;

$white:#FFFFFF;
$black: #000000;
$blue: #7393B8;
$min-title: #CFCFCF;
$sub-title: #153150;
$header-black: #000;
$title: #282626;
$how-it-works-bg: #1F3C5E;
$slider-blue: #32A7F5;
$sub-title-gray: #E0E0E0;
$link-blue: #fff;
$footer-title: #A4A4A4;
$footer-gray: #646262;
