@import 'styles/variables';
@import 'styles/extensions';

.main {
  width: 100%;
  overflow: clip;
  background: initial;  

  &__common-bg {
    position: relative;
    background: radial-gradient(200% 30% at 50% 30%, #2b5481 0%, rgba(35, 71, 165, 0) 200%);
    
    &::before {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      min-height: 250px;
      background: linear-gradient(180deg, #000 7.68%, transparent 100%);
      content: '';
    }
  }

  &__contact-us-form {
    padding-top: 100px;
  }

  &__contact-us-form-wrapper {
    @extend %wrapper;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &__contact-us-form-title-col {
    flex-basis: 30%;
    max-width: 385px;
    color: $black

    span {
      color: $blue;
    }

    p {
      margin-top: 30px;
    }

    @media (max-width: 1024px) {
      max-width: 100%;
      margin-bottom: 50px;
    }

    @media (min-width: 2560px) {
      flex-basis: calc(30% * #{$zoom});
      max-width: calc(345px * #{$zoom});
    }
  }

  &__contact-us-form-title {
    color: $white;
    font-weight: normal;
    font-size: 60px;
    font-family: $secondary-font;
    line-height: 64px;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;

    span {
      color: $footer-title;
    }

    @media (min-width: 2560px) {
      font-size: calc(60px * #{$zoom});
      line-height: calc(64px * #{$zoom});
    }

    @media (min-width: 1024px) and (max-width: 1200px) {
      font-size: 4vw;
      line-height: 4.5vw;
    }

    @media (max-width: 475px) {
      font-size: 40px;
      line-height: 48px;
    }
  }
}